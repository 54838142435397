import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/agreement/index.css";

function Agreement() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    const headerContainer = document.querySelector('.header-container');
    if (headerContainer) {
      const originalBackground = headerContainer.style.backgroundColor;
      headerContainer.style.backgroundColor = '#0c1807';
      return () => {
        headerContainer.style.backgroundColor = originalBackground;
      };
    }
  }, [location]);

  return (
    <section className="agreement-container">
      <div className="common-container">
        <div className="agreement-content">
          <h1 className="agreement-title">Agreement</h1>
          
          <div className="agreement-section">
            <h2>Connect to Sport</h2>
            <h3>Terms & Conditions</h3>
            <p className="last-updated">Last updated: 21/11/2024</p>
            
            <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the http://www.connecttosport.com website (the "Service") operated by Connect to Sport ("CTS")</p>
            
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. Should you accept the terms outlined below this will amount to a contractual relationship between CTS and the Business. These Terms apply to all visitors, users, and others who access or use the Service.</p>
          </div>

          <div className="agreement-section">
            <h2>Subscriptions</h2>
            <p>It is understood by The Business that subscription fees shall be paid to CTS at the beginning of each month. It is also understood that in the event of non-payment of the subscription fees stated below within the duration of 5 working days then CTS shall be entitled to deactivate the Business's access to the Service and to claim an extra 10% charge of net monthly subscription fees.</p>
          </div>

          <div className="agreement-section">
            <h2>Policies</h2>
            <p>The Business shall adhere to the instructions of CTS and Policies concerning the material to be published on the specified area of the Service. Whereas the published material shall not at any time conflict with Taboo of the Country of Operation neither to include any Pornographic and/or Nudity and/or inappropriate and/or illegal content.</p>
            <p>By accessing or using the service the Business also agrees to the privacy policy of the service (Link Below). [https://www.connecttosport.com/privacy]</p>
          </div>

          <div className="agreement-section">
            <h2>Non-Competition</h2>
            <p>The Business agrees not to engage, develop, create, or participate with any individual and/or entity in developing any software and/or service whose nature competes with CTS for the duration of (5) Five years from the date of the termination of the service or non-subscription or renewal for any reason.</p>
            <p>The Business accepts not to engage or to enter into any contractual relation with any competitive company or establishment whose nature of business is similar to the Service offered by CTS for the duration of 2 years from the termination date and/or non-subscription.</p>
          </div>

          <div className="agreement-section">
            <h2>Additional Fees & Charges</h2>
            <p>The Business acknowledges and understands, that Payment Processing through the service is part of the services CTS offers to the Business to enable the Customers and/or Clients of the Business to make payments to them through the Service. Without prejudice to the aforementioned, all payments will be processed by the CTS to the Business subject to the additional payment processing charges applied by the Banks.</p>
          </div>

          <div className="agreement-section">
            <h2>Content</h2>
            <p>CTS Service allows the business to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are solely responsible for the intellectual property violations and/or infringements related to the content shared and/or published by the Business.</p>
          </div>

          <div className="agreement-section">
            <h2>Changes</h2>
            <p>CTS reserves the right, at its sole discretion, to modify or replace these Terms at any time. If the amendments are of substantial nature, then CTS will notify the Business with such amendments via email with a prior 7 days' notice prior to any new terms entering effect. What constitutes a substantial amendment shall be solely determined by CTS.</p>
          </div>

          <div className="agreement-section">
            <h2>Intellectual Property</h2>
            <p>You agree that all materials, products, and services provided on this website are the property of CTS, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trademarks, patents, and other intellectual property. The Business also agrees that it will not reproduce or redistribute the CTS's intellectual property in any way, including electronic, digital, or new trademark registrations.</p>
            <p>The Business hereby also grants CTS a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish.</p>
          </div>

          <div className="agreement-section">
            <h2>Copyrights</h2>
            <p>CTS solely owns and controls all the copyrights and other intellectual property rights in the service. The Business warrant that any content or material given to CTS or published on any of the Services provided by CTS does not infringe any Intellectual Property rights.</p>
            <p>The Business undertakes that entering into a contractual relationship with CTS does not infringe on any patent, design, tradename, or trademark. Therefore, the Business hereby indemnifies, defend, hold CTS, investors, incubators, and shareholders harmless from any actions or claims brought against CTS under this warranty.</p>
          </div>

          <div className="agreement-section">
            <h2>Misuse of the Website</h2>
            <p>The business shall not at any time use the services offered by CTS in any way or take any action that may cause damage and/or impairment of the performance, availability, accessibility, integrity, and security of the services offered by CTS. Using the service for illegal, fraudulent, and harmful purposes may lead to legal liability.</p>
          </div>

          <div className="agreement-section">
            <h2>Registration of Business Account</h2>
            <p>To be eligible for an account on our website, you must be resident in one of the Countries of Operation. You may register for an account with our website by completing and submitting the account registration form on our website, which contains your full name, number, email, user ID, and password. You must not allow any other person to use your account to access the website. You must not use any other person's account to access the website unless you have that person's express permission to do so. You must keep your password confidential, if you become aware of any disclosure of your password you must notify us.</p>
          </div>

          <div className="agreement-section">
            <h2>Mobile App Users</h2>
            <p>CTS might access certain data such as pictures, location, and general information such as date of birth, email, mobile number gender, and other information, by agreeing to the terms and conditions you grant full access to CTS to share this information with CTS partners & 3rd party platforms.</p>
            <p>CTS doesn't support account deletion for sales reports and transaction history, if you require to deactivate or delete your account connect with your business admin or contact us at: info@connecttosport.com</p>
          </div>

          <div className="agreement-section">
            <h2>Applicable Law & Jurisdiction</h2>
            <p>By accessing this service, you agree that the laws of the Hashemite Kingdom Jordan, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between CTS and the Business, its employees, affiliates, consultants, and trainers. The Court of Amman shall have full Jurisdiction to review any disputes arising between CTS and the Business.</p>
          </div>

          <div className="agreement-section">
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at info@connecttosport.com</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Agreement;