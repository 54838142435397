import { React } from "react";
import "../../styles/header/index.css";
import logo from "../../images/header/connecttosport-logo.png";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  function navigateHandler(route) {
    navigate(route);
	}
  return (
    <section className="header-container">
      <div className="common-container">
        <div className="header-content">
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({
              left: 0,
              top: 0,
              behavior: 'smooth',
              });
            }}
          >
          <img src={logo} alt="connect to sport logo" className="header-logo"/>
          </Link>
          <div className="header-links">
            <div className="header-link" onClick={() => navigateHandler("/#features")}>Features</div>
            <div className="header-link" onClick={() => navigateHandler("/#whyUs")}>Why us?</div>
            <div className="header-link" onClick={() => navigateHandler("/#pricing")}>Pricing</div>
            <div className="header-link" onClick={() => navigateHandler("/contact-us")}>Contact</div>
            {/* <div className="header-signin">Admin Login</div> */}
            <div></div>
            <div></div>
            <div></div>
            {/* <div className="header-divider"></div>
            <div className="header-icon">
              <SearchIcon/>
            </div>
            <div className="header-icon">
              <PersonIcon/>
            </div>
            <div className="header-signin">Sign in</div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
