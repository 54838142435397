import { React } from "react";
import "../../styles/hero/index.css";
import hero1 from "../../images/hero/hero-1.png";
import hero2 from "../../images/hero/hero-2.png";
import hero3 from "../../images/hero/hero-3.png";
import thePalmsMaskOne from "../../images/hero/the-palms-mask-1.png";
import thePalmsMaskTwo from "../../images/hero/the-palms-mask-2.png";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [hero1, hero2, hero3];
const masks = [thePalmsMaskOne, thePalmsMaskTwo];

function Hero() {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: "linear",
    arrows: false
  };
  const masksSettings = {
    dots: false,
    infinite: true,
    speed: 1000,           // Immediate transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Time between transitions
    fade: true,        // Remove fade effect
    cssEase: "ease-in-out",    // Remove easing
    arrows: false,
    pauseOnHover: false,
    swipe: false,       // Prevent manual sliding
    draggable: false    // Prevent dragging
  };

  return (
    <>
      <section className="hero-container">
        <div className="common-container">
          <div className="hero-content">
            <div className="hero-slider-container">
              <Slider {...settings} style={{ width: '100vw', maxWidth: '100%', height: '100vh', position: 'relative' }}>
                {images.map((img, index) => (
                  <div key={index}>
                    <img 
                      className="hero-image"
                      src={img} 
                      alt="Hero Image" 
                    />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="hero-text-container">
              <div className="hero-text-content">
                <div className="hero-title">
                  Manage All Your Sports in One Platform – Tennis, Paddle, and More!
                </div>
                <div className="hero-description">
                  Cloud-based software with a fully branded app to simplify scheduling, memberships, payments, and more for tennis, paddle, football, basketball, and beyond.
                </div>
              </div>
              <Link
                to='/contact-us'
                onClick={() => {
                  window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <div className="hero-button btn-success-outlined" onClick={() => navigate('/contact-us')}>
                  <div className="hero-button-text">Get Started</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <section className="masks-container">
        <Slider {...masksSettings} style={{ width: '219px', height: '194px'}}>
          {masks.map((mask, index) => (
            <div key={index}>
              <img src={mask} alt="the Palms Mask"/>
            </div>
          ))}
        </Slider>
        </section>
      </section>
    </>
  );
}

export default Hero;