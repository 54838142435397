import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "../../styles/contactUs/index.css";
import { useLocation } from "react-router-dom";

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please Enter First Name"),
  lastName: Yup.string()
    .required("Please Enter Last Name"),
  email: Yup.string()
    .email("Please Enter Valid Email")
    .required("Please Enter Email"),
  phone: Yup.string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g, "Phone number is not valid")
    .required("Please Enter Phone Number")
    .min(10, "Phone Number must be between 10 to 13 digits")
    .max(13, "Phone Number must be between 10 to 13 digits"),
  details: Yup.string()
    .required("Please Enter Details")
});

const ContactUsForm = ({ handleSubmit: onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    const headerContainer = document.querySelector('.header-container');
    if (headerContainer) {
      const originalBackground = headerContainer.style.backgroundColor;
      headerContainer.style.backgroundColor = '#0c1807';
      return () => {
        headerContainer.style.backgroundColor = originalBackground;
      };
    }
  }, [location]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await onSubmit(values);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact-form-section">
      <div className="common-container">
        <h3 className="contact-form-title">Contact Us</h3>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            details: ''
          }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="contact-form">
              <div className="form-row">
                <div className="form-group">
                  <div className="input-wrapper">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className={`form-input ${errors.firstName && touched.firstName ? "error" : ""}`}
                    />
                    <label className="form-label">First Name</label>
                  </div>
                  {errors.firstName && touched.firstName && (
                    <div className="error-message">{errors.firstName}</div>
                  )}
                </div>

                <div className="form-group">
                  <div className="input-wrapper">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className={`form-input ${errors.lastName && touched.lastName ? "error" : ""}`}
                    />
                    <label className="form-label">Last Name</label>
                  </div>
                  {errors.lastName && touched.lastName && (
                    <div className="error-message">{errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <div className="input-wrapper">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={`form-input ${errors.email && touched.email ? "error" : ""}`}
                    />
                    <label className="form-label">Email</label>
                  </div>
                  {errors.email && touched.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>

                <div className="form-group">
                  <div className="input-wrapper">
                    <Field
                      type="text"
                      name="phone"
                      placeholder="962"
                      maxLength="13"
                      className={`form-input ${errors.phone && touched.phone ? "error" : ""}`}
                    />
                    <label className="form-label">Mobile Number</label>
                  </div>
                  {errors.phone && touched.phone && (
                    <div className="error-message">{errors.phone}</div>
                  )}
                </div>
              </div>

              <div className="form-group full-width">
                <div className="input-wrapper">
                  <Field
                    as="textarea"
                    name="details"
                    placeholder="Start typing..."
                    maxLength="5000"
                    rows="5"
                    className={`form-input ${errors.details && touched.details ? "error" : ""}`}
                  />
                  <label className="form-label">Message</label>
                </div>
                {errors.details && touched.details && (
                  <div className="error-message">{errors.details}</div>
                )}
              </div>

              <button 
                type="submit" 
                className={`submit-button btn-success ${loading ? 'loading' : ''}`}
                disabled={loading}
              >
                {loading ? (
                  <div className="loading-spinner" />
                ) : (
                  "Let's Connect"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

ContactUsForm.defaultProps = {
  handleSubmit: async (values) => {
    console.log(values);
  },
};

export default ContactUsForm;