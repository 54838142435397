import React, { useEffect } from "react";
import "../../styles/whyUs/index.css";
import { useLocation } from "react-router-dom";
import whiteLabel from "../../images/whyUs/white-label.png";
import customPlans from "../../images/whyUs/custom-plans.png";
import support from "../../images/whyUs/support.png";
import security from "../../images/whyUs/security.png";

function WhyUs() {
  const location = useLocation();

  const whyUsCards = [
    {
      id: 1,
      title: "White-Label App",
      description: "Fully branded mobile app with your club's logo, colors, and features",
      background: "#F0F5F8",
      icon: whiteLabel,
      alt: "White Label Icon"
    },
    {
      id: 2,
      title: "Customizable Plans",
      description: "Choose from various features and modules that best fit your club's needs",
      background: "#F8F1F0",
      icon: customPlans,
      alt: "Custom Plans Icon"
    },
    {
      id: 3,
      title: "24/7 Customer Support",
      description: "Dedicated support team to assist you anytime.",
      background: "#F0F8F1",
      icon: support,
      alt: "Support Icon"
    },
    {
      id: 4,
      title: "Trusted Security",
      description: "Ensuring data privacy and compliance with the latest security standards",
      background: "#F3F0F8",
      icon: security,
      alt: "Security Icon"
    },
  ];

  useEffect(() => {
    const element = document.getElementById("whyUs");
    if (element && location.hash.includes("#whyUs")) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="whyUs-container" id="whyUs">
      <div className="common-container">
        <div className="whyUs-content">
          <div className="whyUs-header">
            <h2 className="whyUs-title">Why Choose Connect To Sport?</h2>
            <p className="whyUs-subtitle">
              Better Performance, Seamless Integration, and Custom Branding
            </p>
          </div>
          <div className="whyUs-cards">
            {whyUsCards.map((card) => (
              <div 
                key={card.id} 
                className="whyUs-card"
                style={{ background: card.background }}
              >
                <div className="card-icon">
                  <img 
                    src={card.icon} 
                    alt={card.alt}
                    className="icon-image"
                  />
                </div>
                <div className="card-content">
                  <h3 className="card-title">{card.title}</h3>
                  <p className="card-description">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;