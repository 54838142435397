import React from "react";
import "../../styles/footer/index.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/footer/connecttosport-logo.png";
import linkedinIcon from "../../images/footer/linkedin_logo.png";
import xIcon from "../../images/footer/x_logo.png";
// import instagramIcon from "../../images/footer/instagram_logo.png";
import instagramIcon from "../../images/footer/instagram.svg";
import emailIcon from "../../images/footer/email.png";
import locationIcon from "../../images/footer/location.png";
import visaIcon from "../../images/footer/visa.png";
import mastercardIcon from "../../images/footer/mastercard.png";

function Footer() {
  const navigate = useNavigate();

  function navigateHandler(route) {
    navigate(route);
  }

  const navLinks = [
	{ label: "Features", route: "/#features" },
    { label: "Why us?", route: "/#whyUs" },
    { label: "Pricing", route: "/#pricing" },
    { label: "Contact", route: "/contact-us" },
  ];

  const socialIcons = [
    // { icon: linkedinIcon, alt: "LinkedIn", url: "https://linkedin.com" },
    // { icon: xIcon, alt: "X", url: "https://twitter.com" },
    { icon: instagramIcon, alt: "Instagram", url: "https://www.instagram.com/connecttosport?igsh=MWx2cjI0anlyeXdlbg==" },
  ];

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-left">
            <div className="footer-logo">
			<Link
                    to='/'
                    onClick={() => {
                    window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: 'smooth',
                    });
                    }}
            >
              <img src={logo} alt="Connect To Sport Logo" />
			</Link>
            </div>
            <nav className="footer-nav">
              {navLinks.map((link) => (
                <div 
                  key={link.route} 
                  className="nav-link"
                  onClick={() => navigateHandler(link.route)}
                >
                  {link.label}
                </div>
              ))}
            </nav>
          </div>

          <div className="footer-right">
            <div className="policy-links">
              <Link to="/privacy-policy" className="policy-link">
                Privacy policy
              </Link>
              <Link to="/agreement" className="policy-link">
                Agreement
              </Link>
            </div>
            <div className="social-icons">
              {socialIcons.map((social, index) => (
                <img
                  key={index}
                  src={social.icon}
                  alt={social.alt}
                  className="social-icon"
                  onClick={() => window.open(social.url, '_blank')}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="footer-info">
          <div className="contact-info">
            {/* <div className="info-item">
              <img src={emailIcon} alt="Email" />
              <span>Email: connecttosport@gmail.com</span>
            </div> */}
            <div className="info-item">
              <img src={locationIcon} alt="Location" />
              <span>Location: Amman office, Jaddah office</span>
            </div>
          </div>
        </div>

        <div className="footer-divider" />

        <div className="footer-bottom">
          <div className="copyright">
            All rights reserved - Connect to Sport 2024
          </div>
          <div className="payment-methods">
            <img src={visaIcon} alt="Visa" className="payment-icon" />
            <img src={mastercardIcon} alt="Mastercard" className="payment-icon" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;