import React, { useEffect } from "react";
import "../../styles/fitness/index.css";
import { useLocation } from "react-router-dom";

import arrowIcon from "../../images/fitness/arrow.png";

function Fitness() {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById("fitness");
    if (element && location.hash === "#fitness") {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const handleClick = () => {
    window.open('https://connecttofit.com/', '_blank', 'noopener,noreferrer');
  };

  return (
    <section className="fitness-container" id="fitness">
      <div className="common-container">
        <div className="fitness-card">
          <iframe className="fitness-bg-image"  src="https://lottie.host/embed/8ef6af23-adde-4fa4-86a3-f456ad96da24/VrQVLlo6tQ.json"></iframe>
          
          <div className="fitness-content">
            <div className="fitness-text">
              <h2 className="fitness-title">
                Interested in fitness management?
              </h2>
              <p className="fitness-description">
                Easiest fitness management software, less clicks more power.
              </p>
            </div>

            <button className="fitness-button" onClick={handleClick}>
              <span>Go to Connect to Fit</span>
              <img 
                src={arrowIcon} 
                alt="Arrow" 
                className="arrow-icon"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Fitness;