import React, { useEffect } from "react";
import "../../styles/inAction/index.css";
import { useLocation, useNavigate } from "react-router-dom";

import desktopMobileImage from "../../images/inAction/desktop-mobile-preview.png";

function InAction() {
  const location = useLocation();
  const navigate = useNavigate();

  
  useEffect(() => {
    const element = document.getElementById("inAction");
    if (element && location.hash === "#inAction") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="inAction-container" id="inAction">
      <div className="common-container">
        <div className="inAction-content">
          <div className="inAction-header">
            <h2 className="inAction-title">See It In Action</h2>
            <p className="inAction-subtitle">
              Better Performance, Seamless Integration, and Custom Branding
            </p>
          </div>
          
          <div className="preview-container">
            <div className="desktop-frame">
              <img 
                src={desktopMobileImage} 
                alt="Desktop Preview" 
                className="desktop-image"
              />
            </div>
          </div>

          <button className="try-free-button btn-success" onClick={() => navigate('/contact-us')}>
            Try For Free
          </button>
        </div>
      </div>
    </section>
  );
}

export default InAction;