import { React, useState } from "react";
import Hero from "../../components/Hero";
import Features from "../../components/Features";
import WhyUs from "../../components/WhyUs";
import InAction from "../../components/InAction";
import Pricing from "../../components/Pricing";
import Fitness from "../../components/Fitness";

function Home({ }) {
    return (
        <>
            <Hero/>
            <Features/>
            <WhyUs/>
            <InAction/>
            <Pricing/>
            <Fitness/>
        </>
    );
}

export default Home;
