import { React, useState, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { ReactComponent as LeftArrowIcon } from "./images/left-arrow.svg";
import PrivacyPolicy from './pages/PrivacyPolicy';
import Agreement from './pages/Agreement';
import ContactUsForm from './pages/ContactUs';

function App() {
  const [y, setY] = useState(window.scrollY);
  const [isLoading, setIsLoading] = useState(window.location.pathname == "/" ? true : false);

  window.onscroll = function () {
    setY(window.scrollY);
  };


  return (
    <>
      <Router>
        <div id='app' className={isLoading ? "d-none" : ""}>
          <Suspense fallback={null}>
            {Number(y) >= 120 && (
              <button
                className='go-up'
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <LeftArrowIcon/>
              </button>
            )}
            <Header />

            <Routes>
              <Route exact path='/' element={<Home setIsLoading={setIsLoading} />} />
              <Route exact path='/contact-us' element={<ContactUsForm/>} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicy/>} />
              <Route exact path='/agreement' element={<Agreement/>} />
            </Routes>
            <Footer />
          </Suspense>
        </div>
      </Router>
    </>
  );
}

export default App;
