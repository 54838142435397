import React, { useEffect } from "react";
import "../../styles/features/index.css";
import { useLocation } from "react-router-dom";
import featureImage from "../../images/features/features-1.png";

function Features() {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById("features");
    if (element && location.hash === "#features") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  const featuresList = [
    {
      id: 1,
      text: "Multi-Sport Management: Centralized software to manage tennis, paddle, football, basketball, and more.",
    },
    {
      id: 2,
      text: "Cloud-Based & App-Connected: Access your platform from any device, with your own branded app for players and staff.",
    },
    {
      id: 3,
      text: "Online Booking & Scheduling: Effortlessly manage court reservations, classes, and tournaments.",
    },
    {
      id: 4,
      text: "Automated Payments & Invoicing: Simplified membership payments and invoicing, with integration to popular payment gateways.",
    },
    {
      id: 5,
      text: "Analytics & Insights: Real-time dashboards and analytics to track performance, attendance, and revenue.",
    },
    {
      id: 6,
      text: "User-Friendly Interface: Easy-to-navigate software for both administrators and players.",
    },
    {
      id: 7,
      text: "Multi location management (Franchise only)",
    },
  ];

  return (
    <section className="features-container" id="features">
      <div className="common-container">
        <div className="features-content">
          <div className="features-header">
            <h2 className="features-title">Powerful Features for Every Sport</h2>
            <p className="features-description">
              Cloud-based software with a fully branded app to simplify scheduling,
              memberships, payments, and more for tennis, paddle, football,
              basketball, and beyond.
            </p>
          </div>
          <div className="features-main">
            <img 
              src={featureImage} 
              alt="Features" 
              className="features-image" 
            />
            <div className="features-list">
              {featuresList.map((feature) => (
                <div key={feature.id} className="feature-item">
                  <div className="feature-number">{feature.id}</div>
                  <div className="feature-text">{feature.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;