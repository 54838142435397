import React, { useEffect } from "react";
import "../../styles/privacyPolicy/index.css";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const headerContainer = document.querySelector('.header-container');
    if (headerContainer) {
      const originalBackground = headerContainer.style.backgroundColor;
      headerContainer.style.backgroundColor = '#0c1807';
      return () => {
        headerContainer.style.backgroundColor = originalBackground;
      };
    }
  }, [location]);

  return (
    <section className="privacy-container">
      <div className="common-container">
        <div className="privacy-content">
          <h1 className="privacy-title">Privacy Policy</h1>
          
          <div className="privacy-section">
            <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in Jordan privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>
          </div>

          <div className="privacy-section">
            <h2>What personal information do we collect from the people that visit our website or app?</h2>
            <p>When purchasing or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your experience.</p>
          </div>

          <div className="privacy-section">
            <h2>When do we collect information?</h2>
            <p>We collect information from you when you register on our site or enter information on our site.</p>
          </div>

          <div className="privacy-section">
            <h2>How do we use your information?</h2>
            <p>We may use the information we collect from you when you register, make a sports facility booking, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
            <ul>
              <li>To improve our website in order to better serve you.</li>
              <li>To allow us to better service you in responding to your customer service requests.</li>
              <li>To administer a contest, promotion, survey or other site feature.</li>
              <li>To ask for ratings and reviews of services or products.</li>
              <li>To follow up with them after correspondence (live chat, email or phone inquiries)</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2>How do we protect your information?</h2>
            <p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p>
            <p>We use regular Malware Scanning.</p>
            <p>We use an SSL certificate.</p>
          </div>

          <div className="privacy-section">
            <h2>Do we use 'cookies'?</h2>
            <p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process your sports facility bookings. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
            
            <p>We use cookies to:</p>
            <ul>
              <li>Understand and save user's preferences for future visits.</li>
              <li>Keep track of advertisements.</li>
            </ul>

            <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.</p>
            
            <p>If you turn cookies off, some of the features that make your site experience more efficient may not function properly. It won't affect the user's experience that make your site experience more efficient and may not function properly.</p>
          </div>

          <div className="privacy-section">
            <h2>Third-party disclosure</h2>
            <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>
          </div>

          <div className="privacy-section">
            <h2>Third-party links</h2>
            <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
          </div>

          <div className="privacy-section">
            <h2>Google</h2>
            <p>Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users.</p>
            <p>https://support.google.com/adwordspolicy/answer/1316548?hl=en</p>
            <p>We use Google AdSense Advertising on our website.</p>
            
            <p>Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.</p>
            
            <p>We have implemented the following:</p>
            <ul>
              <li>Remarketing with Google AdSense</li>
              <li>Google Display Network Impression Reporting</li>
              <li>DoubleClick Platform Integration</li>
            </ul>

            <p>We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.</p>
            
            <h3>Opting out:</h3>
            <p>Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.</p>
          </div>

          <div className="privacy-section">
            <h2>According to online general policy, we agree to the following:</h2>
            <ul>
              <li>Users can visit our site anonymously.</li>
              <li>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</li>
            </ul>
            
            <p>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</p>
            
            <p>You will be notified of any Privacy Policy changes:</p>
            <ul>
              <li>On our Privacy Policy Page</li>
            </ul>
            
            <p>Can change your personal information:</p>
            <ul>
              <li>By logging in to your account</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2>How does our site handle Do Not Track signals?</h2>
            <p>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>
          </div>

          <div className="privacy-section">
            <h2>Does our site allow third-party behavioral tracking?</h2>
            <p>It's also important to note that we allow third-party behavioral tracking.</p>
          </div>

          <div className="privacy-section">
            <h2>COPPA (Children Online Privacy Protection Act)</h2>
            <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. which spells out what operators of websites and online services must do to protect children's privacy and safety online.</p>
            <p>We do not specifically market to children under the age of 13 years old.</p>
            <p>Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?</p>
          </div>

          <div className="privacy-section">
            <h2>Fair Information Practices</h2>
            <p>The Fair Information Practices Principles form the backbone of privacy law in Jordan and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>
            
            <p>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</p>
            
            <p>We will notify you via email</p>
            <ul>
              <li>Within 7 business days</li>
            </ul>

            <p>We will notify the users via in-site notification</p>
            <ul>
              <li>Within 7 business days</li>
            </ul>

            <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;