import React, { useEffect } from "react";
import "../../styles/pricing/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import checkIcon from "../../images/pricing/check-mark.png";

function Pricing() {
  const location = useLocation();
  const navigate = useNavigate();


  const pricingPlans = [
    {
      type: "Basic",
      isFeatured: false,
      features: [
        "Payment gateway integration.",
        "Players & club management.",
        "Scheduling & Booking.",
      ],
      description: "Fully branded mobile app with your club's logo, colors, and features.",
    },
    {
      type: "Advance",
      isFeatured: true,
      features: [
        "Advance dashboards & analytics.",
        "Branded Mobile application.",
        "Tournament management.",
      ],
      description: "All Basic & including:",
    },
    {
      type: "Customized",
      isFeatured: false,
      isCustom: true,
      description: "Connect with us for a tailored solution that meets your club's unique needs with custom features and integrations.",
    },
  ];

  useEffect(() => {
    const element = document.getElementById("pricing");
    if (element && location.hash === "#pricing") {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [location]);

  return (
    <section className="pricing-container" id="pricing">
      <div className="common-container">
        <div className="pricing-content">
          <div className="pricing-header">
            <h2 className="pricing-title">Simple, Transparent Pricing</h2>
            <p className="pricing-subtitle">
              Better Performance, Seamless Integration, and Custom Branding
            </p>
          </div>

          <div className="pricing-cards">
            {pricingPlans.map((plan, index) => (
              <div 
                key={index} 
                className={`pricing-card ${plan.isFeatured ? 'featured' : ''} ${plan.isCustom ? 'custom' : ''}`}
              >
                {!plan.isCustom && (
                  <div className="trial-badge">
                    Free trial
                  </div>
                )}

                
                <h3 className="plan-title">{plan.type}</h3>

                <button className={`get-started-btn ${plan.isFeatured ? 'featured btn-success' : 'btn-default'}`} onClick={() => navigate('/contact-us')}>
                  {plan.isCustom ? 'Connect with Us' : 'Get Started'}
                </button>

                <p className="plan-description">
                  {plan.description}
                </p>

                {!plan.isCustom && (
                  <div className="features-list-pricing">
                    {plan.features.map((feature, idx) => (
                      <div key={idx}>
                        <div className={`feature-item-pricing ${plan.type.toLocaleLowerCase()}`}>
                          <div className="check-icon">
                            <img src={checkIcon} alt="check" />
                          </div>
                          <span>{feature}</span>
                        </div>
                        {idx != plan.features.length -1 && <div className="divider"></div>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;